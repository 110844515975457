/* .heading{

} */

.card {
    /* Change background color */
    background-color: rgb(255, 255, 255);
    /* Add border */
    border: 1px solid #bacdd8;
    padding: 4px;
    border-radius: 12px;
  }
  .heading{
    font-size: 2rem;
    font-weight: 700;

  }
  .subHeading{
    text-wrap: wrap;
    font-size: 1rem;

  }
.spacing {
    padding: 1%;
}
.spacing-gap{
    padding: 2%;
}
.content-height{
    height: 120px;
}

// .selected-date-window {
//   justify-content: space-around;
//   align-items: center;


//   display: flex;
//   width : 30%;
// }
.book-input-view{
  width: 80%;
}
.detail-space{
  padding: 10px;
  // align-items: center;
  // justify-content: center;
  // flex-direction: row;


  display: flex;
}

.card-image-size {
  height: 200px;
  width: 300px;
}

// Common scss

.button-style {
  width: 100%;
  padding: 20px;
  height: 40px;
  background-color: #42B677;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 20px;
}

.title-heading {
  padding-left: 40px;
}

.blockquote {
  width: 200px; 
  height: "100px"; 
  background-color: "#f8f8f8"; 
  border-radius: "10px"; 
  margin: "20px"; 
  text-align: "center";
}
.shadow{
  box-shadow: 
  0 0 10px rgba(48, 241, 0, 0.7), 
  inset 0 0 5px rgb(255, 255, 255); 
}
.heading-style{
  font-weight: 600;
  padding-top: 10px;
  font-size: 1.34rem;
  text-wrap: wrap;
}
.boxshadow-enable{
box-shadow: "0 0 10px rgba(66,182,119,255)";
}
.boxshadow-disable{
  box-shadow: "2px solid rgba(255,255,255,0)";
  }
  .form-content-shedular-accordian{
    padding: 100px;
    background-color: white;
    border-radius: 20px;
    width: 95%; 
    padding: 20px;
    // height: 118px;
    justify-content: center;
    display: flex 2;
  }
  .form-content-shedular-accordian-inside{
    padding-left: 20px ;

  }
  .sub-heading-size-sche{
    font-weight: 600;
  padding-top: 10px;
  font-size: 1.5rem;
  }
  .description-size-sche{
    font-size: 1.0rem;
  }
  .low-bottom-padding-she{
    padding-top: 10px;
    font-size: 1.0rem;
  }
  @media (min-width: 1200px) {
    .shedular-alignment-flex {
      justify-content: center;
      align-items : center;
      display: flex;
    }
}
 
  @media (min-width: 576px) {
    .shedular-alignment-flex {
      justify-content: center;
      align-items : center;
      display:  flex 2;
    }
}
  @media  (min-width: 768px) {
   
  }