// My profile

General form container .form-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

/* Profile picture section */
.profile-picture-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.image-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Profile actions for upload and delete */
.profile-actions {
  display: flex;
  gap: 50px;
  justify-content: center;
}

.upload-text {
  cursor: pointer;
  color: #28a745;
  font-size: 14px;
}

.delete-text {
  cursor: pointer;
  background-color: #ed823a;
  color: white;
  font-size: 14px;
  margin-top: 10px;
}

.delete-text:hover {
  text-decoration: underline;
}

/* Form group layout */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.form-group-1 {
  width: 50%;
}

.form-group label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

/* Form input fields */
// input[type="text"],
// input[type="email"],
// select {
//   padding: 10px;
//   font-size: 14px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   outline: none;
//   transition: border-color 0.2s ease-in-out;
// }

// input[type="text"]:focus,
// input[type="email"]:focus,
// select:focus {
//   border-color: #17a2b8;
// }

/* Adjusting input widths */
.form-group-row {
  display: flex;
  gap: 10px;
}

.form-group-row-password {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
}

// .form-group-row input {
//   flex: 1;
// }

/* Dropdown select */
select {
  width: 100%;
}

/* Button group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 24px;
  cursor: pointer;
  margin-left: 45%;
  width: 138.5px;
  height: 52px;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: transparent;
  color: #dc3545;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 37%;
}

.cancel-button:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 15px;
  }

  .form-group-row {
    flex-direction: column;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }
}

/* Account Card container */
.account-card {
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Company Name header */
.account-card-header h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Account Details Section */
.account-card-details {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #6c757d;
}

/* Each account detail */
.account-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Label styling */
.detail-label {
  font-size: 12px;
  margin-bottom: 5px;
}

/* Value styling */
.detail-value {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

/* Adjust responsiveness */
@media (max-width: 768px) {
  .account-card-details {
    flex-direction: column;
    gap: 15px;
  }
}

/* Container for the password change form */
.password-change-form {
  width: 100%;
  // max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Registered email styling */
.registered-email {
  font-size: 14px;
  margin-bottom: 20px;
  color: #212121;
  margin-left: 14px;
}

/* Form group for each input field */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Labels */
.form-group label {
  font-size: 14px;
  margin-bottom: 8px;
  // color: #333;
}

/* Input fields */
input[type="password"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

/* Styling for form row to display fields side-by-side */
.form-row {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}

.form-row .form-group {
  line-height: 20px;
  flex: 1;
}

/* Action buttons */
.form-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Responsiveness for smaller devices */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-actions {
    flex-direction: column;
    gap: 10px;
  }
}
// KYC
.document-upload-form {
  width: 100%;
  height: 100%;
  // max-width: 600px;
  margin: 0;
  margin-bottom: 0px;
  padding: 20px;
  // border: 1px solid #ddd;
  // border-radius: 8px;
  // background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
}

// label {
//   font-size: 16px;
//   font-weight: bold;
//   display: block;
//   margin-bottom: 8px;
// }

.dropdown {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.upload-section {
  margin-bottom: 20px;
}

.upload-box {
  display: block;
  width: 100%;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  transition: border-color 0.3s;
}

.upload-box:hover {
  border-color: #66b2ff;
}

.file-input {
  display: none;
}

.file-info {
  font-size: 14px;
  color: green;
  margin-bottom: 20px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.save-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-btn {
  background-color: #28a745;
  color: white;
}

.save-btn:hover {
  background-color: #218838;
}

.cancel-btn {
  background-color: #f8f9fa;
  color: #333;
}

.cancel-btn:hover {
  background-color: #e2e6ea;
}
.save-button-KYC {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 24px;
  cursor: pointer;
  margin-left: 45%;
  width: 138.5px;
  height: 52px;
}

.save-button-KYC:hover {
  background-color: #218838;
}

.cancel-button-KYC {
  background-color: transparent;
  color: #dc3545;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 37%;
}

.cancel-button-KYC:hover {
  text-decoration: underline;
}
.form-actions-KYC {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

// Account card

.account-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px auto;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  justify-content: center;
}

.header {
  margin-bottom: 20px;
}

.details-container {
  display: flex;
  justify-content: space-around;
}

.detail {
  text-align: center;
}

.label {
  font-size: 14px;
  display: block;
  font-weight: 400;
  color: #757575;
  margin-bottom: 5px;
}

.value {
  font-size: 16px;
  color: #212121;
}
.account-h2 {
  font-size: 20px;
  font-weight: 500;
}

// second header

/* HeaderTabs.css */

.booking-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tabs {
  flex: 1;
}

// .k-tabstrip-items {
//   border-bottom: none; /* Remove bottom border for the tabs */
// }

// .k-tabstrip-items .k-item {
//   font-size: 14px;
//   color: #555;
//   text-transform: uppercase;
// }

// .k-tabstrip-items .k-item.k-state-active {
//   color: black;
//   font-weight: bold;
//   border-bottom: 100px solid #28a745; /* Green underline */
// }

.edit-details-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  top: 320px;
  right: 40px;
}

.edit-details-button:hover {
  background-color: #218838;
}

.profile-mobile-field {
  width: 100%;
  padding: 10px 0px 0px 10px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.profile-email-field {
  width: 100%;
  padding: 10px 10px 0px 0px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

// popup
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
// .popup-content {
//   background: white;
//   padding: 20px;
//   border-radius: 5px;
//   text-align: center;
// }
.popup-content h2 {
  margin: 0 0 10px;
}
.popup-content p {
  margin: 0 0 20px;
}
// .popup-content button {
//   padding: 10px 20px;
//   background-color: #007bff;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }
// .popup-content button:hover {
//   background-color: #0056b3;
// }

.hide-profile-upload {
  border-color: rgba(0, 0, 0, 0);
  background-color: white;
  padding: 0px 0px;
  color: white;
}

.hide-profile-upload .k-upload-dropzone {
  background-color: #ffffff; /* Grey background */
}

.hide-profile-upload .k-upload-status {
  color: #ffffff;
  display: none !important;
}

.hide-profile-upload .k-dropzone-hint {
  color: #ffffff;
  display: none !important;
}

.hide-profile-upload .k-upload-select {
  background-color: #ffffff; /* Button background color */
  color: #fff;
}

.custom-select-message {
  text-align: center;
  padding: 10px;
}

// KYC flow

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2121211f;
  border-radius: 10px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100%;
}
.fileDetails {
  display: flex;
  align-items: center;
}
.fileIcon {
  margin-right: 10px;
}
.actions {
  display: flex;
}
.viewButton {
  color: #42b677;
}
.deleteButton {
  color: #ed823a;
}

.profile-aligh {
  padding-bottom: 20px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-aligh-right {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.kyc-note {
  color: #dc3545;
}
