/* Full bg color */
.k-drawer-content {
    background-color: #EEF3F2;
}

/* 3  views fixed width */
@media (min-width: 1200px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}

input, .k-input, .k-dropdown-wrap, .k-picker-wrap, .k-textbox {
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input, .k-textarea {
    width: 100%;
    border: 1px solid #b0b0b0;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .k-input-md .k-input-button {
    background-color: white;
  }

  .k-upload .k-upload-button {
    background-color: #42B677;
    color: white
  }

  // Calender

  .k-calender .k-calender-td.k-selected .k-link {
    background-color: #42B677;
  }

  .k-calender .k-calender-view .k-today{
    color: #42B677;
  }

  .k-button-flat-primary {
    color: #42B677
  }

  .k-calendar .k-nav-today,
.k-calendar .k-calendar-nav-today {
    color: #42B677;
}

.k-calendar .k-nav-today:hover, .k-calendar .k-nav-today.k-hover, .k-calendar .k-nav-today:focus, .k-calendar .k-nav-today.k-focus,
.k-calendar .k-calendar-nav-today:hover,
.k-calendar .k-calendar-nav-today.k-hover,
.k-calendar .k-calendar-nav-today:focus,
.k-calendar .k-calendar-nav-today.k-focus {
    color: #42B677;
}

.k-calendar .k-calendar-view .k-today {
    color: #42B677;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
    background-color: #42B677;
}

.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
    background-color: #42B677;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
    color: #42B677;
}

.k-calendar.k-invalid, .k-calendar.k-invalid:hover, .k-calendar.ng-invalid.ng-touched, .k-calendar.ng-invalid.ng-dirty {
    border-color: #42B677;
}

.k-calendar .k-range-start .k-calendar-cell-inner, .k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
    background-color: #42B677;
}

  // Radio

  .k-ripple-container .k-radio::after {
    background: #42B677;
    opacity: 0.25;
}

.k-radio:checked, .k-radio.k-checked {
    border-color: #42B677;
    background-color: #42B677;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
    box-shadow: 0 0 0 2px rgba(132, 255, 175, 0.3);
}

// File upload 

.k-upload .k-file-error .k-progress,
.k-upload .k-file-invalid .k-progress {
    background-color: #42B677;
}

// Dropdown item select

.k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
    background-color: #42B677;
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    background-color: #42B677;
  }

  // Dropdown

//   .k-disabled {
//     display: inline-flex;
//     flex-flow: row nowrap;
//     align-items: stretch;
// }

.k-loader-primary {
    color: #42B677;
}

.k-dialog-titlebar {
    background-color: #42B677;
}

.k-button-solid-base.k-selected {
    border-color: #42B677;
    color: white;
    background-color: #42B677;
}

.k-event {
    color: white;
    background-color: #42B677;
}

.k-scheduler-layout td.k-selected, .k-scheduler-layout .k-scheduler-cell.k-selected {
    background-color: rgba(119, 255, 88, 0.25);
}

.k-scheduler-footer {
    display: none;
}

.hourlt-scheduler .k-scheduler-toolbar {
    display: none;
  }

  .k-scheduler-other-month, .k-scheduler .k-other-month {
    background-color: whitesmoke;
}

// My Bookings

.k-animation-container-relative {
    display: block;
}

.k-tabstrip-content {
    border-color: rgba(0, 0, 0, 0.00);
}

.k-tabstrip-items-wrapper {
    border-color: rgba(0, 0, 0, 0.00); 
}

.k-tabstrip-items-wrapper .k-item.k-active {
    border-color: rgba(0, 0, 0, 0.00); 
}

.k-tabstrip-items-wrapper .k-item:focus {
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0)
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    color: black;
    font-size: medium;
    border-bottom-color: #42B677;
    border-width: 3px;
}

.k-tabstrip-items-wrapper .k-item {
    color: #757575;
}

.k-tabstrip-items-wrapper .k-item:hover, .k-tabstrip-items-wrapper .k-item.k-hover {
    color: #757575;
}

a {
    color: #000;
}
.k-card{
    border-radius: 15px;
}

.k-upload .k-upload-files .k-file-size {
    display: none;
}

.k-upload .k-upload-files {
    border-color: rgba(0, 0, 0, 0.00);
}